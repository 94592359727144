export default [
	{ 
		url:'https://tetrix.xyz', 
		name: 'Tetrix',
		shortName: 'Tetrix', 
		description: 'Empowering the web for innovation.',
		icon: './images/dapps/tetrix_250.png', 
	},
	{ 
		url:"https://link.tetrix.xyz", 
		name: "Link", 
		shortName: 'Link', 
		description: "YOUR LINK TO THE FUTURE",
		icon: "./images/dapps/tetrix-link_250.png", 
	},{ 
		url:"https://zombieguillotine.online/", 
		name: "Zombie Guillotine", 
		shortName: 'ZG', 
		description: "A Post-Apocalyptic Zombie Survival Play-to-Earn NFT game.",
		icon: "./images/dapps/zg.png", 
	},
	{ 
		url:"https://www.cryptosheesh.net/", 
		name: "CryptoSheesh", 
		shortName: 'CryptoSheesh', 
		description: "A game that makes your heartbeat skip! Strategies ans feel the adrenaline at it finest.",
		icon: "./images/dapps/cryptosheesh.png", 
	},
	{ 
		url:"https://tier.one/", 
		name: "Tier One", 
		shortName: 'TierOne',
		description: "Asia's premiere eSports and entertainment brand.",
		icon: "./images/dapps/tierone.png", 
	},
	{ 
		url:"https://www.bitskwela.com/", 
		name: "Bitskwela", 
		shortName: 'Bitskwela',
		description: "Crypto Education Platform Made For Filipinos",
		icon: "/images/dapps/bitskwela.png", 
	},
	{ 
		url:"https://uniquecornpr.com/", 
		name: "Uniquecorn Strategies", 
		shortName: 'Uniquecorn',
		description: "PR agency that accelerates startups' growth across Southeast Asia.",
		icon: "/images/dapps/uniquecorn.png", 
	},
	{ 
		url:"https://www.arkofdreams.io/", 
		name: "Ark of Dreams", 
		shortName: 'AoD',
		description: "ENDLESS METAVERSE ADVENTURES CONNECTED TO THE REAL WORLD",
		icon: "/images/dapps/aod.png", 
		certified: false,
	},
];
