import React, { Component } from 'react'
import LinkLogo from '../../images/LinkLogo.svg';
import './index.css';

export default class TakeATour extends Component {
    render() {

        return (
            <div className={'take-a-tour-wrapper'}>
                <div
                    className={'take-a-tour'}
                    /*href="https://link.tetrix.xyz"
                    target="_blank"
                    rel='noopener noreferrer'*/
                >
                    <img src={LinkLogo} className={'take-a-tour-logo'} alt={'Tetrix Link logo'} />
                    <div className={'take-a-tour-content'}>
                        <h3>Get Tetrix Link!</h3>
                        <p>Take control of your information.</p>
                    </div>
                </div>
            </div>

        );
    }
}