 import React, { Component } from 'react'
 import FeaturedDapp from './FeaturedDapp';
 import featuredDapps from '../../data/feature-dapps';

 import './index.css';


 export default class FeaturedDappsCarousel extends Component {
     render(){
         return (
             <div className={'featured-dapps'}>
                 <div className={'featured-dapps-carousel-wrapper'}>
                     <div className={'featured-dapps-carousel'}>
                         { 
                             featuredDapps.map( (dapp, position) => (
                                 <FeaturedDapp 
                                     data={dapp}
                                     key={dapp.url}
                                     position={position}
                                 />
                             ))
                         }
                     </div>
                 </div>
             </div>
         );
     }
 }