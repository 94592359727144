import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/Navbar/';
import Dapp from '../components/Dapp/';
import allDapps from '../data/all-dapps';

export default class Category extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                category: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    };

    state = {
        category: null,
    };

    componentDidMount() {
        const { match } = this.props;
        const category = allDapps.find(
            (cat) => cat.name.toLowerCase().replace(" ", "-") === match.params.category
        );

        if (category) {
            this.setState({ category });
        }
    }

    render() {
        const { category } = this.state;

        if (!category) {
            return null; // or handle loading state
        }

        const { name, dapps } = category;

        return (
            <div>
                <Navbar title={name} />
                <div className="category-wrapper">
                    {dapps
                        .sort((a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0))
                        .map((dapp, i) => (
                            <Dapp data={dapp} key={dapp.url} position={i} />
                        ))}
                </div>
            </div>
        );
    }
}
